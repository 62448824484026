<template>
  <div>
    <slot name="activator" :on="showDialog"></slot>
    <!-- <div href="javascript:;" @click="showDialog()"> -->
    <slot></slot>
    <!-- </div> -->
    <app-dialog v-model="dialog" :title="cpar_header.document_no_" class="dialogdetail" :fullscreen="isMobile" max-width="1400">

      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()" @callback-delete="hideDialog"></action-button>
        <v-spacer></v-spacer>
        <app-document-status v-model="cpar_header.status" class="ml-1"></app-document-status>
        <app-document-status v-model="cpar_header.substatus" class="ml-1"></app-document-status>
      </template>

      <v-row>
        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12">
              <card-expansion title="General">
                <!-- <v-sheet elevation="1" class="pa-0"> -->
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field label="Document No." v-model="cpar_header.document_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Document Date" v-model="cpar_header.document_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Document Type" v-model="cpar_header.document_type" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Area Code" v-model="cpar_header.area_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Department" v-model="cpar_header.dept_name" hide-details outlined dense class="mb-2" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field label="Created By" v-model="cpar_header.created_by_username" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Created Date" v-model="cpar_header.created_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Status" v-model="cpar_header.status" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Sub Status" v-model="cpar_header.substatus" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Ref Doc No." v-model="cpar_header.ref_doc_no_" hide-details outlined dense class="mb-2" readonly :append-icon="(modulePermission('cpar', 'update-ref-doc-no')) ? 'mdi-pencil' : ''" @click:append="editRefDocNo()"></v-text-field>
                    <!-- <v-text-field label="Closing Date" v-model="cpar_header.closing_date" hide-details outlined dense class="mb-2" readonly></v-text-field> -->
                  </v-col>
                </v-row>
                <v-btn color="light" elevation="1" v-if="!loading" @click="redirect('Administration.CPAR.Detail', { id: id })">View Detail</v-btn>
                <!-- </v-sheet> -->
              </card-expansion>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <card-expansion title="Operation" no-padding hide-title>
                <template slot="title">
                  <v-tabs v-model="tab2">
                    <v-tab>Approval</v-tab>
                    <v-tab>PIC</v-tab>
                  </v-tabs>
                </template>
                <v-tabs-items v-model="tab2">
                  <v-tab-item>
                    <ApprovalList v-bind:data-list="cpar_approval"></ApprovalList>
                  </v-tab-item>
                  <v-tab-item>
                    <CparPicList v-bind:data-list="cpar_pic"></CparPicList>
                  </v-tab-item>
                </v-tabs-items>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <app-comment :document_id="parseInt(cpar_header.id)" :source_document="cpar_header.source_document" :document_no_="cpar_header.document_no_"></app-comment>
        </v-col>
      </v-row>
    </app-dialog>

    <v-dialog v-model="dialogRefDocNo" max-width="400px">
      <v-card :loading="loading3">
        <v-card-title>Update Doc No.</v-card-title>
        <v-card-text>
          <v-text-field v-model="refDocNo" dense outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="updateRefDocNo()" :loading="loading3">Update</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialogRefDocNo = !dialogRefDocNo">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import config from "@/constants/config";
import ApprovalList from "./ApprovalList.vue";
import CparPicList from "./CparPicList.vue";
// import SetCloseDocument from "./SetCloseDocument.vue";
import ActionButton from "./ActionButton.vue";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    CparPicList,
    ApprovalList,
    // SetCloseDocument,
    ActionButton
  },
  data() {
    return {
      loading: false,
      loading_delete: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_close_document: false,
      loading_approve: false,
      loading_cancel: false,
      loading_attachment: false,
      dialog: false,
      tab: null,
      tab2: null,
      modal1: false,
      data_detail: {},
      cpar_header: {},
      user: {},
      cpar_description_columns: [
        { text: "No.", value: "line_no_", sortable: false },
        { text: "Deskripsi", value: "description", sortable: false },
        { text: "Klausul", value: "clause", sortable: false },
        { text: "Kategori Temuan", value: "finding_category", sortable: false },
      ],
      cpar_description: [],
      cpar_identification_columns: [
        { text: "Attachment", value: "title", sortable: false },
        { text: "", value: "id", sortable: false, align: "right" },
      ],
      cpar_identification: [],
      cpar_root_cause_a_columns: [
        { text: "No.", value: "row_number_root_a", sortable: false },
        { text: "A. Method", value: "method", sortable: false },
        { text: "PIC", value: "pic_root", sortable: false },
        { text: "Tanggal", value: "root_date", sortable: false },
      ],
      cpar_root_cause_a: [],
      cpar_root_cause_b_columns: [
        { text: "No.", value: "row_number_root_b", sortable: false },
        { text: "B. MAN", value: "man", sortable: false },
        { text: "PIC", value: "pic_root", sortable: false },
        { text: "Tanggal", value: "root_date", sortable: false },
      ],
      cpar_root_cause_b: [],
      cpar_root_cause_c_columns: [
        { text: "No.", value: "row_number_root_c", sortable: false },
        { text: "C. Machine", value: "machine", sortable: false },
        { text: "PIC", value: "pic_root", sortable: false },
        { text: "Tanggal", value: "root_date", sortable: false },
      ],
      cpar_root_cause_c: [],
      cpar_root_cause_d_columns: [
        { text: "No.", value: "row_number_root_d", sortable: false },
        { text: "D. Material", value: "material", sortable: false },
        { text: "PIC", value: "pic_root", sortable: false },
        { text: "Tanggal", value: "root_date", sortable: false },
      ],
      cpar_root_cause_d: [],
      cpar_root_cause_e_columns: [
        { text: "No.", value: "row_number_root_e", sortable: false },
        { text: "E. Environmental", value: "environmental", sortable: false },
        { text: "PIC", value: "pic_root", sortable: false },
        { text: "Tanggal", value: "root_date", sortable: false },
      ],
      cpar_root_cause_e: [],
      cpar_preventive_columns: [
        { text: "No.", value: "row_number_preventive", sortable: false },
        { text: "Corrective & Preventive Action/Pencegahan", value: "preventive", sortable: false },
        { text: "PIC", value: "pic_root", sortable: false },
        { text: "Tanggal", value: "root_date", sortable: false },
      ],
      cpar_preventive: [],
      cpar_verification_columns: [
        { text: "No.", value: "row_number_verification", sortable: false },
        { text: "Correction / Perbaikan", value: "verification", sortable: false },
        { text: "PIC", value: "pic_root", sortable: false },
        { text: "Tanggal", value: "root_date", sortable: false },
      ],
      cpar_verification: [],
      cpar_approval_columns: [
        { text: "Approval", value: "approval_label", sortable: false },
        { text: "User", value: "username", sortable: false },
        { text: "Approval Status", value: "approved", sortable: false },
        { text: "Approve Date", value: "approved_date", sortable: false },
      ],
      cpar_approval: [],
      cpar_comment: [],
      cpar_pic: [],

      cpar_types: [
        { text: "", value: '' },
        { text: "Baru", value: 'N' },
        { text: "Berulang", value: 'R' },
      ],

      dialogRefDocNo: false,
      refDocNo: "",
      loading3: false,
    };
  },
  watch: {
    dialog(n, o) {
      if (o == true && n == false) {
        this.$emit("callback");
      }
    },
  },
  computed: {
    totalPrice() {
      var total = 0;
      this.cpar_description.forEach((item) => {
        total = total + item.quantity * item.unit_price;
      });
      return total;
    },
    showDeleteBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.cpar_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("cpar", "delete") && !this.inArray(this.cpar_header.status, ["approved"]) && this.inArray(this.cpar_header.status, ["open"])) {
        return true;
      }
      return false;
    },
    showReopenBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel", "open"])) return false;

      if (this.cpar_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("cpar", "reopen") && !this.inArray(this.cpar_header.status, ["open"])) {
        return true;
      }
      return false;
    },
    showCancelBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("cpar", "cancel") && !this.inArray(this.cpar_header.status, ["approved"]) && !this.inArray(this.cpar_header.status, ["cancel"])) {
        return true;
      }
      return false;
    },
    showSendApprovalBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("cpar", "send-approval") && this.cpar_header.status == "open") {
        return true;
      }
      return false;
    },
    showApproveBtn() {
      if (!this.inArray(this.cpar_header.status, ["waiting-approval"])) return false;

      if (this.cpar_header.status == "waiting-approval") {
        let ap = false;
        if (typeof this.cpar_approval !== "undefined") {
          if (this.cpar_approval.length >= 0) {
            this.cpar_approval.forEach((item) => {
              let approved = item.approved;
              if (!approved) {
                if (item.username == this.$store.state.loginInfo.username) {
                  ap = true;
                }
              }
            });
          }
        }

        return ap;
      }
      return false;
    },
    showEditBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("cpar", "modify") && this.cpar_header.status == "open") {
        return true;
      }
      return false;
    },
    showCloseBtn() {
      if (!this.inArray(this.cpar_header.status, ["approved"])) return false;

      if (!this.inArray(this.cpar_header.substatus, ["", "on-process"])) return false;

      if (this.modulePermission("cpar", "close")) {
        return true;
      }

      return false;
    },
    showSetPicBtn() {
      if (!this.inArray(this.cpar_header.status, ["approved"])) return false;

      if (this.modulePermission("cpar", "set-pic") && this.cpar_header.status == "approved") {
        return true;
      }
      return false;
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    showDialog() {
      this.dialog = true;
      this.getDetail(true);
    },

    hideDialog() {
      this.dialog = false
    },

    async getDetail(refresh = false) {
      this.loading = true;

      this.cpar_header = {};
      this.cpar_description = [];
      this.cpar_identification = [];
      this.cpar_root_cause_a = [];
      this.cpar_root_cause_b = [];
      this.cpar_root_cause_c = [];
      this.cpar_root_cause_d = [];
      this.cpar_root_cause_e = [];
      this.cpar_preventive = [];
      this.cpar_verification = [];
      this.cpar_approval = [];
      this.cpar_comment = [];
      this.user = {};

      if (refresh == true) this.AxiosStorageRemove("GET", "cpar/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("cpar/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.cpar_header = res.data.data.cpar_header;
          this.cpar_description = res.data.data.cpar_description;
          this.cpar_identification = res.data.data.cpar_identification;
          this.cpar_root_cause_a = res.data.data.cpar_root_cause_a;
          this.cpar_root_cause_b = res.data.data.cpar_root_cause_b;
          this.cpar_root_cause_c = res.data.data.cpar_root_cause_c;
          this.cpar_root_cause_d = res.data.data.cpar_root_cause_d;
          this.cpar_root_cause_e = res.data.data.cpar_root_cause_e;
          this.cpar_preventive = res.data.data.cpar_preventive;
          this.cpar_verification = res.data.data.cpar_verification;
          this.cpar_approval = res.data.data.cpar_approval;
          this.cpar_comment = res.data.data.cpar_comment;
          this.cpar_pic = res.data.data.cpar_pic;
          this.user = res.data.data.user;

          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    /**
     * reopenData
     * Method untuk re-open dokumen
     * mengembalikan status ke open
     */
    reopenData() {
      let post = async () => {
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/reopen", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Re-open document?", post);
    },

    /**
     * approveData
     * Method untuk meng-approved dokumen
     * Dokumen harus berstatus Waiting Approval
     */
    approveData() {
      let post = async () => {
        this.loading_approve = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/approve", formData)
          .then((res) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Approve document?", post);
    },

    /**
     * cancelData
     * Method untuk meng-cancel dokumen
     * Dokumen harus berstatus Open
     */
    cancelData() {
      let post = async () => {

        this.loading_cancel = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/cancel", formData)
          .then((res) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Cancel document?", post);
    },

    /**
     * deleteData
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Cancel / Open
     */
    deleteData() {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/delete", formData)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.dialog = false;
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Cancel document?", post);
    },

    /**
     * sendApproval
     * Method untuk mengirimkan Approval
     * Dokumen harus berstatus Open
     */
    sendApproval() {
      let post = async () => {
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/send-approval", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Send Approval?", post);
    },

    async saveAttachment(data) {
      this.loading_attachment = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("cpar_header_id", this.cpar_header.id);
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("file_src", data.file_src);
      await this.$axios
        .post("cpar/attachment/save", formData)
        .then((res) => {
          this.loading_attachment = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);

          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_attachment = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async updateAttachment(data) {
      this.loading_attachment = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", data.id);
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("file_src", data.file_src);
      await this.$axios
        .post("cpar/attachment/update", formData)
        .then((res) => {
          this.loading_attachment = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);

          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_attachment = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async deleteAttachment(data) {
      const post = async () => {
        this.loading_attachment = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", data.id);
        await this.$axios
          .post("cpar/attachment/delete", formData)
          .then((res) => {
            this.loading_attachment = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_attachment = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Delete attachment?", post);
    },

    async printDocument() {
      this.loading = true;
      this.showLoadingOverlay(true);

      var config2 = {
        params: {
          id: this.id,
        },
        cacheConfig: false,
      };
      await this.$axios
        .get("cpar/print/session", config2)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.showLoadingOverlay(false);
            const url = config.apiUrl + "cpar/print?id=" + this.id + "&key=" + res.data.data.key;

            // console.log(url);
            this.goToExternalURL(url);
          }, 2000);
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    // printDocument() {
    //   const url =
    //     config.apiUrl +
    //     "cpar/print?id=" +
    //     this.id +
    //     "&key=" +
    //     this.$store.state.apiToken;

    //   // console.log(url);
    //   this.goToExternalURL(url);
    // },

    editRefDocNo() {
      this.refDocNo = this.cpar_header.ref_doc_no_;
      this.dialogRefDocNo = true;
    },

    async updateRefDocNo() {
      this.loading3 = true;
      this.showLoadingOverlay(true);

      var formData = new FormData();
      formData.append("id", this.id);
      formData.append("ref_doc_no_", this.refDocNo);
      await this.$axios
        .post("cpar/update-ref-doc-no", formData)
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.refreshDetail();
          this.dialogRefDocNo = false;
          this.loading3 = false;
          this.showLoadingOverlay(false);
        })
        .catch((error) => {
          this.loading3 = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.refreshDetail();
          this.dialogRefDocNo = false;
          this.loading3 = false;
        });
    }
  },
};
</script>
